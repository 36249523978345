.jumbotron {
    margin-bottom: 0;
}

.footer {
    padding:10px;
    text-align: right;
    padding-top:80px;
}


.App {
    min-height: 500px;
}

.correctionsPage {
    min-height: 500px;
}

@media (max-width:767px) {
    .display-3 {
      font-size: 2.5rem;
    }

}