.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
  border: gray 1px dashed;
  padding: 3px;
  margin: 3px;
  cursor: pointer;
}

.frac.noHighlight {
  border: none;
}

.frac.noHighlight .symbol{
  margin:0;
}

.mag {
  border: gray 1px dashed;
  cursor: pointer;
  display: inline-block;
  margin: 3px;
  padding: 3px;
}

.frac .mag {
  border: transparent 1px dashed;
}

.mag {
  white-space: nowrap;
}

.frac .mag.clickHighlight {
  border: gray 1px solid;
}

.frac > span {
  display: block;
  padding: 0.1em;
}



.frac span.symbol {
  overflow: hidden;
  height: 1px;
  background: black;
  border: 0;
  padding: 0;
  margin: 5px 0;
} 

.col-1, .col-2 {
  width: 50%;
  float: left;
}

.step:not(:first-child) {
  margin: 40px 0;
}

#svgWrapper {
  position: fixed;
}

.noteContentWrapper {
  display: none;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  
}

.noteWrapper a {
  display: block;
  margin-bottom: 10px;
}

.noteContentWrapper.show {
  display: block;
}

.noteWrapper {
  margin-top:10px;
}

.clickHighlight {
  border: 1px solid black; 
}

.clickHighlight.top, .highlight.top {
 color: blue;
}

.clickHighlight.bottom, .highlight.bottom {
  color: red;
 }

.highlight {
  border: 1px solid grey;
}

 .left {
   float: left;
 }

 .right {
  float: right;
}

 .left:after {
  content: "";
  display: table;
  clear: both;
 }

 .clear {
   clear: both;
 }

.page {
   padding-top: 48px;
   padding-bottom: 48px;
 }

 
 .p-a {
  position: absolute;
  left:0;
  top: 0;
}

.error {
  color: red;  
}

textarea, input {
  padding: 4px;
}

textarea.error {
  border: 1px solid red;
  color: inherit;
}

.mt-1 { margin-top: 8px; }
.mt-2 { margin-top: 16px; }

.pt-2 { padding-top: 16px; }

.wrapper {
  max-width: 600px;
  position: relative;
  padding-bottom: 10px;
}

.d-b {
  display: block;
}


.of-h {
  overflow: hidden;
}

form {
  margin-bottom: 100px;
}

.wrapper img {
  width: 100%;
}

.rotate {
  animation: rotation 24s infinite linear;
}

#sun, #innerWheel {
  -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
}

.sun-rotate {
  animation: rotation 24s linear;
}

.sun-reset {
  transform: rotate(0deg);
}


.dayRotate {
  position: relative;
}



@keyframes rotation {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}

.content-container{
margin-top: 56px;
}

#opticsPage #svgWrapper {
  width: 36%;
}

#my-svg{
  left: auto;
  position: fixed;
  width: 36%;
}










input, textarea {
  border: 1px solid black;
  border-radius: 3px;
}

input[type="submit"] {
  background-color: white; 
  font-family: Georgia, 'Times New Roman', Times, serif;
}

input[type="submit"]:hover {
  background-color: black;
  color: white; 
}

.circle-border {
  border: 1px solid black;
  width: 14px;
  height: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  line-height: 12px;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: -4px;
}

.information-message {
  display: block;
  position: absolute;
  width: 250px;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  border-radius: 3px;
}

span.circle-border:after {
  content: " ";
  border-top: 1px solid black;
  border-left: 1px solid black;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 16px;
  left: 3px;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
}

span.circle-border:after, .information-message { 
  display: none;      
}

.more-info:hover span.circle-border:after, .more-info:hover .information-message {
  display: block;
}